/* src/pages/ProfileEdit.css */

/* Basic styling for the profile edit form */
.container {
    margin-top: 30px;
}

h2 {
    margin-bottom: 20px;
    text-align: center;
}

form {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

form .form-group {
    margin-bottom: 15px;
}

form .form-group label {
    font-weight: bold;
}

form .form-group input {
    border-radius: 5px;
}

form .btn-primary {
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
}
