/* src/pages/AdminDashboard.css */
.admin-dashboard-nav {
    background: #f8f9fa;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: 100vh;
    position: sticky;
    top: 0;
}

.admin-dashboard-main {
    padding: 2rem;
}

.dashboard-title {
    margin-bottom: 1.5rem;
    font-size: 2rem;
    font-weight: bold;
    color: #343a40;
}

.dashboard-stats {
    display: flex;
    gap: 1rem;
}

.stat-card {
    flex: 1;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.stat-icon {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.stat-text {
    font-size: 1.5rem;
}
