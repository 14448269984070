/* src/pages/InstructorProfile.css */
.instructor-profile {
    background: #f8f9fa;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.instructor-profile .img-fluid {
    width: 100%;
    height: auto;
}
