/* src/components/MyAccount.css */
.my-account-page {
    display: flex;
    height: 100vh;
}

.my-account-container {
    display: flex;
    flex: 1;
    align-items: center;
}

.form-section {
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.image-section {
    background: url('../assets/2150352184.jpg') no-repeat center center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
}

.image-content {
    text-align: center;
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.5);
    /* Optional overlay */
}

.image-content h2 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
    margin-bottom: 1rem;
}

.image-content p {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
}