/* src/pages/AdminUsers.css */
.admin-layout {
    display: flex;
    height: 100vh;
}

.admin-content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
}

.admin-users .text-center {
    margin-bottom: 20px;
}

.admin-users .table th,
.admin-users .table td {
    vertical-align: middle;
}

.admin-users .table .btn {
    margin-right: 10px;
}

.dropify-wrapper {
    border: 2px dashed #007bff;
    background-color: #e9ecef;
    border-radius: 5px;
}

.dropify-message span.file-icon {
    color: #007bff;
}

.dropify-preview .dropify-render img {
    border-radius: 5px;
}
