/* src/components/SingleCourse.css */
.single-course {
    padding: 2rem 0;
}

.single-course h1,
.single-course h2 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
}

.single-course p,
.single-course li {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
}

.single-course .course-video img {
    width: 100%;
    height: auto;
}

.single-course .resources-list {
    list-style-type: none;
    padding-left: 0;
}

.single-course .resources-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.single-course .share a {
    margin-right: 1rem;
    font-size: 1.5rem;
    color: #000;
}

.single-course .share a:hover {
    color: #007bff;
}

.single-course .card {
    margin-bottom: 2rem;
}

.single-course .advertisement img {
    width: 100%;
    height: auto;
}

.single-course .btn-primary,
.single-course .btn-secondary {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
}