/* src/components/MyCourses.css */

.my-courses-container {
    padding: 2rem;
    background: #f8f9fa;
}

.my-courses-container h2 {
    margin-bottom: 1.5rem;
    font-size: 2rem;
    font-weight: bold;
    color: #343a40;
}

.error {
    color: red;
    margin-bottom: 1rem;
}

.courses-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.course-card {
    flex: 1;
    min-width: 300px;
    max-width: 350px;
    margin: 1rem 0;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}