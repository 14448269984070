/* src/components/SearchResults.css or a relevant CSS file */

.course-image {
    width: 100%;
    height: 300px; /* Set a fixed height for the image */
    object-fit: cover;
    object-position: center center;
}

/* src/components/AllCourses.css */

.all-courses {
    padding: 2rem;
    background: #f8f9fa;
}

.all-courses h2 {
    margin-bottom: 1.5rem;
    font-size: 2rem;
    font-weight: bold;
    color: #343a40;
}

.error {
    color: red;
    margin-bottom: 1rem;
}

.card {
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-decoration: none !important;
}

.card img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.card-body {
    padding: 1rem;
}


h5 {
    font-size: 23px;
    font-weight: bold;
}

h4 {
    font-size: 14px;
    color: #AAAAAA !important;
    font-size:medium
}
