/* src/components/UpcomingCourses.css */
.upcoming-courses {
    padding: 2rem 0;
}

.upcoming-courses h2 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
    margin-bottom: 2rem;
}

.card-title {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
}

.card-text {
    font-family: 'Source Sans Pro', sans-serif;
}

h5 {
    font-size: 23px;
    font-weight: bold;
}

h4 {
    font-size: 14px;
    color: #AAAAAA;
    font-size:medium
}

.course-image {
    width: 100%;
    height: 300px; /* Set a fixed height for the image */
    object-fit: cover;
    object-position: center center;
}