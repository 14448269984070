/* src/pages/Profile.css */

.profile-form {
    background: #f8f9fa;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-form h2 {
    margin-bottom: 1.5rem;
    font-size: 2rem;
    font-weight: bold;
    color: #343a40;
}

.message {
    color: green;
    margin-bottom: 1rem;
}

.error {
    color: red;
    margin-bottom: 1rem;
}

.dropify-wrapper .dropify-message span.file-icon {
    font-size: 1.5rem;
}