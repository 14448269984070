/* src/pages/AdminCourses.css */
.admin-courses {
    display: flex;
}

.courses-content {
    flex-grow: 1;
    padding: 20px;
}

.table {
    margin-top: 20px;
}
