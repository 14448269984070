/* src/components/Newsletter.css */
.newsletter {
    background-color: #f8f9fa;
    border-radius: 10px;
    /* Adjust the border radius as needed */
    margin: 0 auto;
    width: 100%;
    max-width: 1140px;
    /* Ensure it matches the container width */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Optional: Add a subtle shadow for better appearance */
}

.newsletter-container {
    padding: 2rem;
}

.newsletter h2 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
}

.newsletter p {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    margin-bottom: 1.5rem;
}

.newsletter .form-group {
    display: flex;
    justify-content: center;
    align-items: center;
}

.newsletter .form-group input {
    flex: 1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.newsletter .form-group button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.alert {
    text-align: center;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
}