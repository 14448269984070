/* src/components/AdminDashboardNav.css */
.admin-dashboard-nav {
    width: 250px;
    background: #343a40;
    color: #fff;
    height: 100vh;
    padding-top: 20px;
}

.admin-dashboard-nav .nav-link {
    color: #fff;
}

.admin-dashboard-nav .nav-link:hover {
    background: #495057;
}

.admin-dashboard-nav .nav-icon {
    margin-right: 10px;
}
