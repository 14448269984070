/* src/components/Header.css */
.navbar {
    background-color: #ffffff !important;
}

.navbar-brand {
    font-size: 1.5rem;
    font-weight: bold;
    color: #343a40;
}

.nav-link {
    color: #343a40 !important;
    margin-right: 1rem;
}

.nav-link:hover {
    color: #343a40 !important;
}

.navbar-toggler {
    border: none;
}

.navbar-toggler-icon {
    color: #343a40 !important;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.ml-auto {
    margin-left: auto;
}

.fab {
    font-size: 1.2rem;
}

@media (max-width: 767px) {
    .navbar-collapse {
        text-align: center;
    }
}