/* src/components/SearchBar.css */
.search-bar {
    position: relative;
    background-image: url('../../public/src/2148913245.jpg');
    /* Replace with your image path */
    background-size: cover;
    background-position: center;
    color: white;
    padding: 5rem 0;
}

.search-bar::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    /* Adjust the overlay color and opacity */
}


.search-bar .container {
    position: relative;
    z-index: 1;
}

.search-bar h2 {
    color: white;
    font-size: 40px;
    font-weight: bold;
}

.search-bar .input-group {
    margin-bottom: 1rem;
}

.search-bar .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
}
