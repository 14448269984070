/* src/components/Contact.css */
.contact-page {
    padding: 2rem 0;
}

.contact-page h1,
.contact-page h2,
.contact-page h3,
.contact-page h5 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
}

.contact-page p,
.contact-page .card-text,
.contact-page .form-group label {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
}

.contact-page .card {
    margin-bottom: 2rem;
}

.contact-page .card-title {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
}

.contact-page .btn-primary {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
}

.contact-page .g-recaptcha {
    margin-bottom: 1rem;
}