/* src/index.css */
@import './components/UpcomingCourses.css';
@import './components/Newsletter.css';

/* Global styles */
body {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.search-bar {
  background-color: #343a40;
  color: white;
  padding: 2rem 0;
}

a {
  color: #343a40;
  text-decoration: none;
}

.upcoming-courses {
  padding: 2rem 0;
}

.newsletter {
  background-color: #f8f9fa;
  padding: 2rem 0;
  border-radius: 10px;
  max-width: 1140px;
  margin: 0 auto;
}

.footer {
  background-color: #343a40;
  color: white;
  padding: 2rem 0;
}