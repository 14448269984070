/* src/components/DashboardNav.css */

.dashboard-nav {
    background: #343a40;
    height: 100vh;
    padding-top: 1rem;
    color: #fff;
}

.dashboard-nav .nav-link {
    color: #fff !important;
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    font-size: 1rem;
}

.dashboard-nav .nav-link:hover {
    background: #495057;
}

.dashboard-nav .nav-icon {
    margin-right: 0.75rem;
    font-size: 1.2rem;
}
