/* src/pages/Dashboard.css */

.dashboard .sidebar {
    background: #343a40;
    color: #fff;
    min-height: 100vh;
}

.dashboard .main-content {
    background: #f8f9fa;
    min-height: 100vh;
    padding: 2rem;
}
