/* src/components/DashboardMain.css */

.dashboard-main {
    padding: 2rem;
    background: #f8f9fa;
}

.dashboard-title {
    margin-bottom: 2rem;
    font-size: 2rem;
    font-weight: bold;
    color: #343a40;
}

.dashboard-stats {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.stat-card {
    background: #fff;
    padding: 1rem;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    flex: 1;
    margin: 1rem;
    max-width: 300px;
    transition: transform 0.3s ease;
    position: relative;
}

.stat-card:hover {
    transform: scale(1.05);
}

.stat-icon {
    position: absolute;
    left: -5px;
    top: 15px;
    background: #e0e0e0;
    padding: 0.5rem 1rem;
    border-radius: 0 10px 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stat-icon .icon {
    font-size: 2rem;
    color: #343a40;
}

.stat-text {
    margin-top: 2rem;
}

.stat-text span {
    display: block;
    font-size: 2.5rem;
    color: #343a40;
}

.stat-text p {
    font-size: 1rem;
    color: #6c757d;
}