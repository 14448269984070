/* src/pages/AdminTransactions.css */

/* Basic styling for the admin layout */
.admin-layout {
    display: flex;
}

.admin-content {
    flex: 1;
    padding: 20px;
    background-color: #f9f9f9;
}

/* Table styling */
.table {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.table th, .table td {
    vertical-align: middle;
}

.table th {
    /* background-color: #007bff;
    color: #fff; */
}

.table td {
    color: #333;
}

/* Button styling */
.button-primary {
    background-color: #007bff;
    border: none;
}

.button-danger {
    background-color: #dc3545;
    border: none;
}

/* Modal styling */
.modal-header {
    background-color: #007bff;
    color: #fff;
}

.modal-body {
    background-color: #f9f9f9;
}
