/* src/components/Banner.css */

.banner {
    background: url('../../public/src/2148913245.jpg') no-repeat center center;
    background-size: cover;
    padding: 6rem 0;
    text-align: center;
    color: #fff;
    position: relative;
}

.banner::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.banner-title {
    position: relative;
    font-size: 2.5rem;
    font-weight: bold;
    z-index: 1;
}