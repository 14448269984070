/* src/pages/MyTransactions.css */
.my-transactions {
    background: #f8f9fa;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.my-transactions h2 {
    margin-bottom: 1.5rem;
    font-size: 2rem;
    font-weight: bold;
    color: #343a40;
}

.error {
    color: red;
}
